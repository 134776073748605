import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormUtil } from '@core/utils/form-util';
import { ButtonType, LayoutConfig } from '@shared/services/layout-config.service';
import { FormConfig, FormHelper, FormState } from '@core/enums/form-config.enum';
import { DxpBaseEntityComponent } from '@core/base/base-entity.class';
import { CsvUploadResponse } from '@core/models/csv-upload-response.model';
import { AppInjector } from '../../app-injector.service';
import { getUserFriendlyName } from '@core/models/entity-config.model';
import { AppRoutes } from '../enums/routes.enum';
import { IEntityBase } from '@core/interfaces/entity-base';
import { IUploadBase } from '@core/models/upload-base.model';
import { UnsavedChangesResponse } from '@core/interfaces/unsaved-changes.interface';
import { UnsavedChangesWarningService } from '@core/services/unsaved-changes-warning.service';

@Component({
  template: '',
})
export class BaseUploadComponent<EntityModel extends IEntityBase>
  extends DxpBaseEntityComponent<EntityModel>
  implements OnInit
{
  public form: UntypedFormGroup;
  public submitting = false;
  protected uploadService: IUploadBase;

  // Injectable dependencies:
  protected formBuilder: UntypedFormBuilder;

  protected formConfig: FormConfig = {
    state: FormState.isIdle,
    submitIcon$: new BehaviorSubject<string>('fas fa-file-upload'),
  };

  protected unsavedChangesWarningService: UnsavedChangesWarningService;

  constructor(public route: ActivatedRoute) {
    super(route);

    this.formBuilder = AppInjector.getInjector().get(UntypedFormBuilder);
    this.unsavedChangesWarningService = AppInjector.getInjector().get(UnsavedChangesWarningService);
  }

  public hasUnsavedChanges(): UnsavedChangesResponse {
    return this.unsavedChangesWarningService.defaultHasUnsavedChanges(this.form, this.submitting);
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:pagehide', ['$event'])
  public refreshUnsavedChangesPopup(event: any): string | boolean {
    return this.unsavedChangesWarningService.handleBrowserRefresh(this.form, event);
  }

  public ngOnInit(): void {
    this.createForm();

    this.generateDefaultConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((defaultConfig) => {
        this.layoutConfigService.addConfig(this.overwriteDefaultConfig(this.entity, this.createConfig(defaultConfig)));
      });
  }

  public createConfig(defaultConfig: LayoutConfig): LayoutConfig {
    return {
      ...defaultConfig,
      title: this.translateService.stream('dxp.sidebar.manage'),
      subtitle: this.translateService.stream(`dxp.upload.${getUserFriendlyName(this.entityConfig)}.title`),
      backUrl: ['/', AppRoutes.admin, AppRoutes.manage],
      actionButtons: [
        {
          type: ButtonType.SUBMIT,
          icon: this.formConfig.submitIcon$,
          label: this.translateService.stream('button.submit'),
          callback: () => {
            this.submit();
          },
        },
      ],
    };
  }

  public submit(): void {
    if (!this.checkFormValidity() || FormHelper.isSubmitting(this.formConfig)) {
      return;
    }

    this.formConfig.state = FormState.isSubmitting;
    this.formConfig.submitIcon$.next('fas fa-spinner fa-pulse');

    this.uploadRequest()
      .pipe(
        finalize(() => {
          this.resetSubmitButton();
        }),
      )
      .subscribe(
        (response) => {
          this.submitting = true;
          this.updateForm(response);
          this.successfullySubmitted(response);
        },
        () => {
          this.submitting = false;
          this.resetSubmitButton();
        },
      );
  }

  public uploadRequest(): Observable<any> {
    return this.uploadService.uploadCsvFiles(this.form.value.files);
  }

  public resetSubmitButton(): void {
    this.formConfig.state = FormState.isIdle;
    this.formConfig.submitIcon$.next('fas fa-file-upload');
  }

  public successfullySubmitted(response?: EntityModel): void {
    const title = this.translateService.instant('toast.success.title');
    const entityName = this.translateService.instant(`dxp.${getUserFriendlyName(this.entityConfig)}.entity_name`);
    const message = this.translateService.instant('dxp.uploaded.success', { entity: entityName });

    this.notificationService.success(title, message);
  }

  public updateForm(response?: EntityModel): void {
    this.form.get('result').setValue(response);
    this.form.get('files').reset();
  }

  public checkFormValidity(): boolean {
    if (this.form.invalid) {
      FormUtil.markAsTouched(this.form);
    }

    return this.form.valid;
  }

  public resultHasErrors(result: CsvUploadResponse): boolean {
    return result && result.errors && typeof result.errors === 'object' && Object.keys(result.errors).length > 0;
  }

  public getResultErrorsListAsArray(result: CsvUploadResponse): any[] {
    return this.resultHasErrors(result)
      ? Object.keys(result.errors).map((key) => {
          return {
            key,
            message: result.errors[key],
          };
        })
      : [];
  }

  public getResultErrorLength(result: CsvUploadResponse): number {
    return this.resultHasErrors(result) ? Object.keys(result.errors).length : 0;
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      files: [null, this.filesValidator()],
      isLoading: [false],
      isSaved: [false],
      result: [null],
    });
  }

  protected filesValidator(): ValidatorFn[] {
    return [Validators.required];
  }
}
